$font-family: 'af_arial'; //Roboto, sans-serif;
$font-family-bold: 'af_arial_bold';
$font-family-reg: 'mf_montserrat';
$font-family-reg-bold: 'mf_montserrat_bold';

$theme-max-width: 1350px;

$admin-toolbar-height: 56px;
$admin-sidenav-width: 250px;
$sidenav-user-block-height: 156px;

$color_primary:#FFC908;
$color_secondary: #707170;
$color_white:  #ffffff;